<template>
	<div style="overflow: hidden;">
		<!-- <router-view></router-view> -->
		<router-view data-aos="fade-down" data-aos-easing="linear" data-aos-duration="0"></router-view>
	</div>
</template>

<script>
	export default {
	}
</script>

<style>
</style>